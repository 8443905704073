<template>
  <div class="label-button">
    <span>
      <slot></slot>
    </span>
  </div>
</template>

<script>
export default {
  name: 'Label',
}
</script>

<style lang="scss" scoped>
.label-button {
  display: inline;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: rgb(48, 48, 48);
  background: #dfdfdf;
  border-radius: 6px;
  padding: 5px 10px;

  &.running,
  &.active {
    color: #8950fc;
    background: #eee5ff;
  }
  &.importing {
    color: #eee5ff;
    background: #8950fc;
  }
  &.paused,
  &.inactive {
    color: #f64e60;
    background: #ffe2e5;
  }
  &.halted {
    color: #ffe2e5;
    background: #f64e60;
  }
  &.preparing {
    color: #1bc5bd;
    background: #c9f7f5;
  }
  &.configuring,
  &.scheduled {
    color: #ffa800;
    background: #fff4de;
  }
  &.menu-nav-light {
    color: #3699ff;
    background: #eee5ff;
    border-radius: 4px;
  }
}
</style>